$main-color: #e8635e;
$background-color: #f3f3f3;
$outer-color: #b1b8c0;
$light-gray: #f2f4f6;
$border-color: #787579;
$text-color: #000000;
$theme-blue: #2c3572;

@mixin mobile-responsive {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin desktop-responsive {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile-root-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  margin: 0px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

html {
  overflow-x: hidden;
}

.no-text-decoration {
  text-decoration: none !important;
  color: white;
}
