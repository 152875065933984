@use "src/base.scss";

.no-text-decoration {
  text-decoration: none !important;
  color: white;
}

.Navbar {
  display: flex;
  justify-content: start;
  width: 100%;
  height: 7vh;
  position: fixed;
  color: #dfdfdf;
  text-align: left;
  top: 0;
  font-size: 16px;
  @media (max-width: 480px) {
    font-size: 3vw;
  }

  .navbar-logo {
    font-size: 24;
    font-weight: 600;

    @media (max-width: 480px) {
      font-size: 3vw;
    }
  }

  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 12px 100px;
    width: 100%;
    z-index: 999;

    @media (max-width: 480px) {
      width: 100%;
      padding: 12px 40px;
    }
  }

  .link-1 {
    color: white;
  }
}
