@use "src/base.scss";

:root {
  --border: 0 !important;
  --profile-size: 180px;
}

.Team h4 {
  font-weight: 10;
  font-size: 14px;
}

.text-highlight {
  font-style: bolder;
}

h1 {
  font-size: 50px;

  @include base.mobile-responsive {
    font-size: 34px;
  }
}

.Team {
  // margin-top: 50px;
  display: flex;
  justify-content: center;

  height: 100vh;

  background-color: #f3f3f3;

  @include base.mobile-responsive {
    margin-top: 7vh;
    height: 100%;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  max-width: 1200px;
  width: 100%;

  border: var(--border);
}

.title {
  margin: auto;
  height: 100px;
}

.team-grid-container {
  display: flex;
  max-width: 1020px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;

  margin-bottom: 50px;

  @include base.mobile-responsive {
    display: grid;
    max-width: 400px;
    width: 100%;
  }
}

.profile-1,
.profile-2,
.profile-3,
.profile-4 {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px;
}

#profile-img1,
#profile-img2,
#profile-img3,
#profile-img4 {
  width: var(--profile-size);
  margin-bottom: 20px;
}

.profile-title {
  font-size: 14px;
}

.profile-name {
  height: 40px;
}
.profile-background {
  margin-bottom: 20px;
  line-height: 12px;
}
