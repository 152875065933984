@use "src/base.scss";

:root {
  --border: 0 !important;
  --orange: #fb5758 !important;
  --black: 0 !important;
}

.homepage h1,
.homepage h2,
.homepage h3,
.homepage h4,
.homepage h5,
.homepage p,
.homepage span {
  font-family: "Poppins", sans-serif;
}

.homepage h2 {
  font-size: 28px;

  @include base.mobile-responsive {
    font-size: 24px;
  }
}
.homepage h3 {
  font-size: 24px;
  font-weight: lighter;

  @include base.mobile-responsive {
    font-size: 14px;
  }
}

.no-text-decoration {
  text-decoration: none !important;
  color: white;
}

.homepage {
  background-color: black;
  background-image: url("../../assets/images/background.png");
  color: white;
  height: 100vh;

  @media all and (max-width: 600px) {
    background-position: center;
  }

  .bg-image {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
  }

  .main-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    border: 2px solid red;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    text-align: start;
    gap: 12px;

    padding: 100px;
    padding-top: 240px;

    border: var(--border);

    @media all and (max-width: 600px) {
      padding: 40px;
      padding-top: 40vh;
    }

    #subtitle {
      font-size: 16px;
      font-weight: 200;
      margin-bottom: 0px;
      color: #dfdfdf;

      @media all and (max-width: 600px) {
        font-size: 3vw;
      }
    }

    #title {
      font-size: 46px;
      font-weight: 500;
      padding-right: 40vw;
      margin: 0;

      @media all and (max-width: 600px) {
        font-size: 6vw;
        padding-right: 20vw;
      }
    }
  }
}

.homepage .service-link-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  margin-top: 30px;

  border: var(--border);

  @include base.mobile-responsive {
    margin-top: 15px;
  }

  .link {
    background-color: none;
    color: #dfdfdf;

    .service-button,
    .hiring-button {
      padding: 12px 24px;
      @media (max-width: 480px) {
        padding: 10px 20px;
      }
    }

    .hiring-button {
      background-color: #1076f9;
    }
  }
}

.homepage .link input[type="submit"] {
  border: 1px solid #dfdfdf;
  background: none;
  color: #dfdfdf;
  font-weight: 300;

  border-radius: 0px;
  padding: 10px 40px;
  font-size: 14px;

  @media all and (max-width: 600px) {
    padding: 4px 20px;
    font-size: 3vw;
    font-weight: 00;
  }
}

.homepage .link input[type="submit"]:hover {
  transform: scale(1.05);
  cursor: pointer;
}
